// Logout.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import './logout.css';

const Logout = ({isLoggedIn, setIsLoggedIn}) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('accessToken');
    setIsLoggedIn(false);
    navigate('/login');
  };

  return (
    isLoggedIn && ( // Prikazuje Logout dugme samo ako je korisnik ulogovan
      <div>
        <FontAwesomeIcon icon={faRightFromBracket} color='white' cursor='pointer' onClick={handleLogout}  title="Logout"/>
        {/* <button className='logout-button' >Logout</button> */}
      </div>)
  );
};

export default Logout;
