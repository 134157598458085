import { Outlet, useNavigate } from "react-router-dom";
import React from 'react'

import { useEffect, useState } from "react";
import { baseURL } from "../../constants/const";
import axios from "axios";
import Logout from "../Logout/Logout";
import Navbar from "../Navbar/Navbar";

const CHECK_AUTH_ENDPOINT = baseURL+'/check-auth';

const Layout = () => {
  
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    checkAuth();
  }, []);

  const checkAuth = async () => {
    const accessToken = localStorage.getItem('accessToken');

    if (accessToken) {
      try {
        const formData = new URLSearchParams();
        formData.append('accessToken', accessToken);
        const response = await axios.post(CHECK_AUTH_ENDPOINT, formData, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        });
        const isValid = response.data.isValid;
        setIsLoggedIn(isValid);

        if (!isValid) {
          handleLogout();
        }
      } catch (error) {
        // Handlovanje grešaka
      }
    } else {
      navigate('/login');
    }
  };
  const handleLogout = () => {
    localStorage.removeItem('accessToken');
    setIsLoggedIn(false);
    navigate('/login');
  };
  return (
    <main className="App">
      <Navbar isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn}/>
      <Outlet />
    </main>
  )
}

export default Layout
