import React, { useEffect, useState } from 'react';
import { getPages, getPermisisons, getUsersExceptLogin, postRequest } from '../../api/axios';
import { getPermissions, getUsername } from '../../context/AuthFunc';
import "./accessRights.css";
import { PERMISSION_ENDPOINT } from '../../constants/const';
import Error from '../Error/Error';
import Success from '../Success/Success';
const extractPageIdsFromToken = (permissions) => {
    return permissions.map(permission => parseInt(permission.split('_')[0]));
};
const AccessRights = () => {
    const [users, setUsers] = useState([]);
    const [pages, setPages] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [permissions, setPermissions] = useState([]); 
    const [userPermissions, setUserPermissions] = useState([]); 
    const [errMsg, setErrMsg] = useState('');
    const [succ, setSucc] = useState('');

    useEffect(() => {
        fetchUsers();
        setUserPermissions(getPermissions());
        
    }, []);

    useEffect(() => {
        fetchPages();
        if (selectedUser) {
            fetchUserPermissions(selectedUser.id);
        }
    }, [selectedUser]);

    const fetchUsers = async () => {
        const username = getUsername();
        const response = await getUsersExceptLogin(username);
        setUsers(response);
    };

    const fetchPages = async () => {
        const response = await getPages();
        console.log(response);
        const allowedPageIds = extractPageIdsFromToken(userPermissions);
        const filteredPages = response.filter(page => allowedPageIds.includes(parseInt(page.id)));
        setPages(filteredPages);
        console.log(filteredPages);
    };

    const fetchUserPermissions = async (user_id) => {
        const response = await getPermisisons(user_id);
        setPermissions(response);
    };

    const handlePermissionChange = async (pageId, permission, isChecked) => {
        const action = isChecked ? 'add' : 'remove';

        const formData = new URLSearchParams();
        formData.append('user_id', selectedUser.id);
        formData.append('page_id', pageId);
        formData.append('action', action);

        const response = await postRequest(PERMISSION_ENDPOINT, formData);

        if (response.success) {
            fetchUserPermissions(selectedUser.id);
            setSucc("Uspesno");
            setErrMsg('');
        } else {
            setErrMsg("Neuspesno dodavanje");
        }
    };
    const handleUserChange = (e) => {
        const selectedUserId = (e.target.value);
        const user = users.find(user => user.id === selectedUserId);
        if (user) {
            setSelectedUser(user);
        } else {
            console.error('Selected user not found');
        }
    };
    return (
        <div>
            <h2>Upravljanje pravima pristupa korisnika</h2>
            <select onChange={handleUserChange} className='access-select'>
                <option value="">Izaberi korisnika...</option>
                {users.map(user => (
                    <option key={user.id} value={user.id}>{user.username}</option>
                ))}
            </select>

            {selectedUser && (
                <div>
                    <h3 className='access-h3'>Prava pristupa za korisnika: {selectedUser.username}</h3>
                    <table className='access-table'>
                        <thead>
                            <tr className='access-table-head'>
                                <th>Stranica</th>
                                <th>Dozvola</th>
                            </tr>
                        </thead>
                        <tbody>
                            {pages.map(page => (
                                <tr key={page.id} className='access-table-body'>
                                    <td>{page.name}</td>
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={permissions.some(p => p.page_id === page.id)}
                                            onChange={(e) => handlePermissionChange(page.id, 'view', e.target.checked)}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
            {errMsg && <Error message={errMsg} onClose={() => setErrMsg('')} />}
            {succ && <Success message={succ} onClose={() => setSucc('')} />}
        </div>

    );
};

export default AccessRights;

{/*                <td>
                    <input
                      type="checkbox"
                      checked={permissions.some(p => p.page_id === page.id && p.permission === 'view')}
                      onChange={(e) => handlePermissionChange(page.id, 'view', e.target.checked)}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      checked={permissions.some(p => p.page_id === page.id && p.permission === 'edit')}
                      onChange={(e) => handlePermissionChange(page.id, 'edit', e.target.checked)}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      checked={permissions.some(p => p.page_id === page.id && p.permission === 'create')}
                      onChange={(e) => handlePermissionChange(page.id, 'create', e.target.checked)}
                    />
                  </td> */}