import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import './newTicket.css';
import { getReaders, getTicketIdExist, postRequest } from '../../api/axios';
import Error from '../Error/Error';
import Success from '../Success/Success';
import Notification from '../Notification/Notification'; // Import Notification component
import { getUsername } from '../../context/AuthFunc';

const NewTicket = () => {
    const [ticketId, setTicketId] = useState('');
    const [surname, setSurname] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [hFrom, setHFrom] = useState('07:00');
    const [hTo, setHTo] = useState('23:00');
    const [dayNo, setDayNo] = useState('30');
    const [entryNo, setEntryNo] = useState('30');
    const [dailyNo, setDailyNo] = useState('0');
    const [readers, setReaders] = useState([]);
    const [submitter, setSubmitter] = useState('');
    const [selectedReaders, setSelectedReaders] = useState([]);
    const [errMsg, setErrMsg] = useState('');
    const [succ, setSucc] = useState('');
    const [showNotification, setShowNotification] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const fetchReaders = async () => {
            try {
                const response = await getReaders();
                setReaders(response); // Assuming the response contains a list of readers
                setSelectedReaders(response.map(reader => reader.id));
            } catch (error) {
                console.error('Error fetching readers:', error);
            }
        };

        fetchReaders();
        setSubmitter(getUsername);
    }, []);
    
    const formatTime = (time) => {
        if (time.length === 5) { // Format H:i
          return `${time}:00`;
        }
        return time;
      };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formattedHFrom = formatTime(hFrom);
        const formattedHTo = formatTime(hTo);

        if(!ticketId){
            setErrMsg('Ticket number is required.');
            return;
        } 
        if(selectedReaders.length === 0) {
            setSelectedReaders(readers.map(reader => reader.id));
        }

        const newTicket = {
            ticket_id: ticketId,
            surname,
            name,
            phone,
            h_from: formattedHFrom,
            h_to: formattedHTo,
            day_no: dayNo,
            entry_no: entryNo,
            daily_no: dailyNo,
            readers: selectedReaders.includes('all') ? readers.map(reader => reader.id) : selectedReaders,
            submitter: submitter
        };

        try {
            const response = await postRequest('/write-qr', new URLSearchParams(newTicket));
            if (!response.success) {
                setErrMsg("Something went wrong...");
                setTimeout(() => setErrMsg(''), 3000);
            } else {
                setSucc('Successfully added!');
                setTicketId('');
                setSurname('');
                setName('');
                setPhone('');
                setHFrom('07:00');
                setHTo('23:00');
                setDayNo('30');
                setEntryNo('30');
                setDailyNo('0');
                setSelectedReaders([]);
                setTimeout(() => setSucc(''), 3000);
            }
            setShowNotification(true);
        } catch (error) {
            setErrMsg('Failed to add ticket');
            setShowNotification(true);
        }
    };
    useEffect(() => {
        console.log('Selected readers:', selectedReaders);
        console.log('H FROM: ', hFrom);
    }, [selectedReaders, hFrom]);

    const handleReaderChange = (e) => {
        const value = e.target.value;
        if (value === 'all') {
            setSelectedReaders((prev) =>
                prev.includes('all') ? [] : readers.map(reader => reader.id)
            );
        } else {
            setSelectedReaders((prev) =>
                prev.includes(value)
                    ? prev.filter((reader) => reader !== value)
                    : [...prev.filter((reader) => reader !== 'all'), value]
            );
        }
    };

    return (
        <div className="container-ticket">
            <h1>Nova karta</h1>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="ticketId">Serijski broj karte:</label>
                    <input
                        type="text"
                        id="ticketId"
                        className="ticketId"
                        value={ticketId}
                        onChange={(e) => setTicketId(e.target.value)}
                        title='Read from RFID reader'
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="name">Ime 
                                        korisnika:
                    </label>
                    <input
                        type="text"
                        id="name"
                        className="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="surname">Prezime korisnika:</label>
                    <input
                        type="text"
                        id="surname"
                        className="surname"
                        value={surname}
                        onChange={(e) => setSurname(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="phone">Telefon korisnika:</label>
                    <input
                        type="text"
                        id="phone"
                        className="phone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="hFrom">Ulaz od:</label>
                    <input
                        type="time"
                        id="hFrom"
                        className="hFrom"
                        value={hFrom}
                        onChange={(e) => setHFrom(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="hTo">Ulaz do:</label>
                    <input
                        type="time"
                        id="hTo"
                        className="hTo"
                        value={hTo}
                        onChange={(e) => setHTo(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="dayNo">Broj dana vazenja karte:</label>
                    <input
                        type="text"
                        id="dayNo"
                        className="dayNo"
                        value={dayNo}
                        onChange={(e) => setDayNo(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="entryNo">Ukupan broj prolaza:</label>
                    <input
                        type="text"
                        id="entryNo"
                        className="entryNo"
                        value={entryNo}
                        onChange={(e) => setEntryNo(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="dailyNo">Broj dnevnih prolaza:</label>
                    <input
                        type="text"
                        id="dailyNo"
                        className="dailyNo"
                        value={dailyNo}
                        onChange={(e) => setDailyNo(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="readers">Barijera na kojoj karta ima dozvoljen prolaz:</label>
                    <button type="button" className='select-reader-button' onClick={() => setIsModalOpen(true)}>
                        Izaberi
                    </button>
                </div>
                <Modal
                    isOpen={isModalOpen}
                    onRequestClose={() => setIsModalOpen(false)}
                    contentLabel="Select Readers"
                    className="modal"
                    overlayClassName="overlay"
                >
                    <div className="modal-header">
                        <h2>Izaberi citac</h2>
                        <button className="close-button" onClick={() => setIsModalOpen(false)}>×</button>
                    </div>
                    <form>
                        <div className='checkbox-container'>
                            <label className="reader-label">
                                <input
                                    type="checkbox"
                                    value="all"
                                    checked={selectedReaders.length === readers.length}
                                    onChange={handleReaderChange}
                                    className="reader-checkbox"
                                />
                                Sve
                            </label>
                        </div>
                        {readers.map(reader => (
                            <div key={reader.id} className='checkbox-container'>
                                <label className="reader-label">
                                    <input
                                        type="checkbox"
                                        value={reader.id}
                                        checked={selectedReaders.includes(reader.id)}
                                        onChange={handleReaderChange}
                                        className="reader-checkbox"
                                    />
                                    {reader.name}
                                </label>
                            </div>
                        ))}
                    </form>
                    <button className="ticket-button" onClick={() => setIsModalOpen(false)}>OK</button>
                </Modal>
                <button type="submit" className="ticket-button">Dodaj</button>
                {errMsg && <Error message={errMsg} onClose={() => setErrMsg('')} />}
                {succ && <Success message={succ} onClose={() => setSucc('')} />}
            </form>
        </div>
    );
};

export default NewTicket;
