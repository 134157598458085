import React, { useState } from 'react';
import './newReader.css';
import { getReaderIdExist, postRequest } from '../../api/axios';
import Error from '../Error/Error';
import Success from '../Success/Success';

const NewReader = () => {
  const [id, setId] = useState('');
  const [serialNumber, setSerialNumber] = useState('');
  const [name, setName] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [idError, setIdError] = useState('');
  const [succ, setSucc] = useState('');
  const checkIdExists = async () => {
    if (id) {
      try {
        const response = await getReaderIdExist(id);
        if (response.success) {
          setIdError('Reader exists.');
          return false;
        } else {
          setIdError('');
          return true;
        }
      } catch (error) {
        console.error('Error checking ID:', error);
        setIdError('Greška pri proveri citaca.');
        return false;
      }
    }
  };

  const handleSubmit = async(event) => {
    event.preventDefault();
   
    if (id === '') {
      setErrMsg('ID required.');
      return;
    } 
    const userExists = await checkIdExists();
    if (!userExists) {
        return;
    }
    // slanje backendu
    const formData = new URLSearchParams();
    formData.append('id', id);
    formData.append('serial_num', serialNumber);
    formData.append('name', name);
    formData.append('active', isActive);
    const response = await postRequest("/add-readers", formData);
    if (!response.success) {
      setErrMsg("Something went wrong...");
    } else {
      setIdError('');
      setId('');
      setSerialNumber('');
      setName('');
      setIsActive(false);
      setSucc('Successfully added!');
    }
  };

  return (
    <div className="container-reader">
      <h1>Nov citac</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="id">ID:</label>
          <input
            type="text"
            id="id"
            className="id"
            value={id}
            onChange={(e) => setId(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="serialNumber">Serijski broj:</label>
          <input
            type="text"
            id="serialNumber"
            className="serialNumber"
            value={serialNumber}
            onChange={(e) => setSerialNumber(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="name">Naziv:</label>
          <input
            type="text"
            id="name"
            className="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="checkbox-container">
          <input
            type="checkbox"
            id="isActive"
            checked={isActive}
            onChange={(e) => setIsActive(e.target.checked)}
          />
          <label htmlFor="isActive">Aktivan</label>
        </div>
        <button type="submit" className="reader-button">Dodaj</button>
        {errMsg && <Error message={errMsg} onClose={() => setErrMsg('')} />}
        {succ && <Success message={succ} onClose={() => setSucc('')} />}
      </form>
      {idError && <Error message={idError} onClose={() => setIdError('')} />}
    </div>
  );
};

export default NewReader;
