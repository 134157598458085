import React from 'react'
import './Login.css'
import { useRef, useEffect, useState, useContext } from 'react'
import AuthContext from '../../context/AuthProvider';
import { baseURL } from '../../constants/const'; 
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const LOGIN_ENDPOINT = baseURL + '/auth';
const Login = () => {
    const {setAuth} = useContext(AuthContext);
    const userRef = useRef();
    const errRef = useRef();
    const navigate = useNavigate();

    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        userRef.current.focus();
    }, []);
    
    useEffect(() => {
        setErrMsg('')
    }, [user, pwd]);

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const formData = new URLSearchParams();
        formData.append('user', user);
        formData.append('pwd', pwd);
    
        try {
            const response = await axios.post(LOGIN_ENDPOINT, formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            });
    
            //console.log(JSON.stringify(response?.data));
    
            const accessToken = response?.data?.accessToken;
            setAuth({ user, accessToken });
            console.log(accessToken);
            localStorage.setItem('accessToken', accessToken);
            setUser('');
            setPwd('');
            setSuccess(true);
            navigate('/');
        } catch (error) {
            if (!error?.response) {
                setErrMsg('No server response' + error.message);
            } else if (error.response?.status === 400) {
                setErrMsg('Wrong username or password');
            } else if (error.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login failed.');
            }
            errRef.current.focus();
        }
    }
  return (
    <> { success? (
        <section>
            <h1>You are logged in!</h1>
            <br/>
            <p>
                <a href='#'>Go to Home</a>
            </p>
        </section>
    ) : (
    <section>
        <div className='container-login'>
        <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live='assertive'>{errMsg}</p>
        <h1>Log In</h1>
        <form onSubmit={handleSubmit}>
            <label htmlFor='username'>Username: </label>
            <input className='username' 
            type='text'
                    id='username'
                    ref={userRef}
                    autoComplete='off'
                    required
                    onChange={(e) => setUser(e.target.value)}
                    value={user}
            />
            <label htmlFor='password'>Password: </label>
            <input className='pass' 
            type='password'
                    id='password'
                    autoComplete='off'
                    required
                    onChange={(e) => setPwd(e.target.value)}
                    value={pwd}
            />
            <button className='login-button'>Log In</button>
        </form>
        </div>
    </section>
    )}</>
  )
}

export default Login
