import React from 'react';
import { useNavigate } from 'react-router-dom';

const DataTable = ({ data, columns, entityName, h2 }) => {
  const navigate = useNavigate();

  const handleEdit = (id) => {
    navigate(`/${entityName}/edit/${id}`);
  };

  const handleDelete = (id) => {
    if (window.confirm('Da li ste sigurni da želite da obrišete ovaj zapis?')) {
      // Implementacija logike za brisanje
      console.log(`Delete ${entityName} with id: ${id}`);
      // Nakon brisanja osveži listu
    }
  };

  return (
    <div>
      <h2>{h2}</h2>
      <table>
        <thead>
          <tr>
            {columns.map(column => (
              <th key={column.accessor}>{column.Header}</th>
            ))}
            <th>Akcije</th>
          </tr>
        </thead>
        <tbody>
          {data.map(item => (
            <tr key={item.id}>
              {columns.map(column => (
                <td key={column.accessor}>{item[column.accessor]}</td>
              ))}
              <td>
                <button onClick={() => handleEdit(item.id)}>Izmeni</button>
                <button onClick={() => handleDelete(item.id)}>Obrisi</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DataTable;
