import React from 'react';
import { Link } from 'react-router-dom';
import './unauthorized.css'; // Importujte CSS datoteku ako želite stilizirati komponentu

const Unauthorized = () => {
    return (
        <div className="unauthorized-container">
            <h1>Unauthorized</h1>
            <p>Nemate prava da pristupite ovoj stranici.</p>
            <Link to="/">Pocetna</Link>
        </div>
    );
};

export default Unauthorized;
