import React, { useEffect, useState } from 'react'
import DisplayForm from '../Table/DisplayForm'
import { getInfoPerTID } from '../../api/axios';

const PerTicketNumber = ({ticket_id}) => {
    const [data, setData] = useState([]);

    const fetchData = async () => {
        try {
            const response = await getInfoPerTID(ticket_id);
            console.log(response);
            setData(response); 
        } catch (error) {
            console.error('Error fetching ticket info:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const keys = [
        'name', 'surname', 'phone', 'create_time', 'remaining_passes', 'remaining_days', 'remaining_daily', 'exceed_date'
    ];
    const labels = {
        name: 'Ime',
        surname: 'Prezime',
        phone: 'Broj telefona',
        create_time: 'Datum kreiranja',
        remaining_passes: 'Preostali prolazi',
        remaining_days: 'Preostali dani',
        remaining_daily: 'Preostali dnevni prolazi',
        exceed_date: 'Datum isteka'
    };
  return (
    <div>
      <DisplayForm data={data} keys={keys} labels={labels}/>
    </div>
  )
}

export default PerTicketNumber
