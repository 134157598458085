// src/components/DownloadApp/DownloadApp.js
import React from 'react';
import { downloadTicketsAppURL } from '../../constants/const';
import './downloadApp.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

const DownloadApp = () => {
  const handleDownload = () => {
    const appUrl = downloadTicketsAppURL; // Zamenite stvarnim URL-om
    const link = document.createElement('a');
    link.href = appUrl;
    link.download = '';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
        <FontAwesomeIcon icon={faDownload}  color='white' cursor='pointer' onClick={handleDownload} title="Download nove verzije Tickets APP"/>
    </div>
  );
};

export default DownloadApp;
