import React, { useEffect, useState } from 'react';
import './monitoring.css';
import { getMonitoring } from '../../api/axios';

const Monitoring = () => {
    const [events, setEvents] = useState([]);
    const [timestamp, setTimestamp] = useState(Date.now());
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredEvents, setFilteredEvents] = useState([]);

    const fetchData = async () => {
        try {
            const date = new Date();
            const year = date.getFullYear().toString().slice(-2);
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');

            const formattedDate = `20${year}-${month}-${day}`;

            const response = await getMonitoring(formattedDate, formattedDate);

            setEvents(response.slice(0, 15)); // Prikazujemo samo prvih 15 rezultata
        } catch (error) {
            console.error('Error fetching events:', error);
        }
    };

    useEffect(() => {
        // Prvo učitavanje podataka
        fetchData();

        // Ažuriranje podataka svakih sekundu
        const interval = setInterval(() => {
            fetchData();
            setTimestamp(Date.now()); // Ažuriramo timestamp kako bi komponenta ponovo renderovala
        }, 1000);

        // Očisti interval pri demontiranju komponente
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        // Filtriraj događaje na osnovu termina za pretragu
        if (Array.isArray(events)) {
            setFilteredEvents(
                events.filter(event =>
                    Object.values(event).some(val =>
                        val.toString().toLowerCase().includes(searchTerm.toLowerCase())
                    )
                )
            );
        }
    }, [searchTerm, events]);

    return (
        <div className='monitoring-container'>
            <h1>Monitor</h1>
            <p>Azuriranje: {new Date(timestamp).toLocaleTimeString()}</p>
            <input
                type="text"
                placeholder="Pretraga..."
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                className='search-input'
            />
            {filteredEvents.length > 0 ? (

            <table className='monitoring-table'>
                <thead>
                    <tr>
                        <th>Karta</th>
                        <th>Datum i vreme</th>
                        <th>Barijera</th>
                        <th>Rezultat</th>
                        <th>Ime</th>
                        <th>Prezime</th>
                        <th>Preostalo prolaza</th>
                        <th>Preostalo dana</th>
                        <th>Preostalo danas</th>
                        <th>Datum isteka</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredEvents.map((event, index) => (
                        <tr key={`${event.serial_num}-${index}`} className={event.reject === 'Success' ? 'success' : 'fail'}>
                            <td>{event.serial_num}</td>
                            <td>{event.datetime}</td>
                            <td>{event.mac_addr}</td>
                            <td>{event.reject}</td>
                            <td>{event.name}</td>
                            <td>{event.surname}</td>
                            <td>{event.remaining_passes}</td>
                            <td>{event.remaining_days}</td>
                            <td>{event.remaining_daily}</td>
                            <td>{event.exceed_date}</td>
                        </tr>
                    ))}
                </tbody>
            </table>) : (<p>Empty</p>)}
        </div>
    );
};

export default Monitoring;
