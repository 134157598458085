import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { editTicket, editTicketUser, getReaders, getTicket, postRequest } from '../../api/axios';
import Error from '../Error/Error';
import Success from '../Success/Success';

const EditTicket = () => {
    const { ticket_id } = useParams();
    const [ticket, setTicket] = useState(null);
    const [user, setUser] = useState(null);
    const [phone, setPhone] = useState('');
    const [readers, setReaders] = useState([]);
    const [errMsg, setErrMsg] = useState('');
    const [succ, setSucc] = useState('');
    const navigate = useNavigate();

    const fetchData = async () => {
        const response = await getTicket(ticket_id);
        setTicket(response.ticket);
        setUser(response.user);
        console.log(response);
        setPhone(response.user.phone);
    };
    const fetchReaders = async () => {
        const response = await getReaders();
        setReaders(response);
    };
    useEffect(() => {
        // Fetch ticket and user data
        fetchData();
        fetchReaders();
    }, [ticket_id]);

    const handleSave = async () => {
        if (!user.name || !user.surname || !phone || !ticket.day_no || !ticket.entry_no || !ticket.daily_no) {
            setErrMsg('Sva polja moraju biti popunjena.');
            return;
        }


        if (phone !== user.phone) {
            console.log(phone)
            // Phone number changed, create new user
            const updatedUser = { ...user, phone: phone };
            console.log(user);
            const response = await postRequest('/add/ticket-user', updatedUser);
            if (response.success) {
                const newUserId = response.other;
                const updatedTicket = { ...ticket, id: newUserId };
                // Update ticket with new user id
                console.log(updatedTicket);
                const update = await editTicket(updatedTicket);
                if (update.success) {
                    fetchData();
                    setSucc('Uspesna izmena!');
                } else{
                    setErrMsg('Greska prilikom izmene.');
                    setTimeout(() => setErrMsg(''), 3000);
                }
                
            }
        } else {
            // Phone number not changed, just update the ticket and user
            const updateTU = await editTicketUser(user);
            const updateT = await editTicket(ticket);
            if (updateT.success && updateTU.success) {
                fetchData();
                setSucc('Uspesna izmena!');
                setTimeout(() => setSucc(''), 3000);
            } else {
                setErrMsg('Greska prilikom izmene.');
                setTimeout(() => setErrMsg(''), 3000);
            }
        }
    };

    const handleCheckboxChange = (readerId, checked) => {
        const updatedReaders = checked
            ? [...ticket.readers, readerId] // Add readerId if checked
            : ticket.readers.filter(id => id !== readerId); // Remove readerId if unchecked
        setTicket({ ...ticket, readers: updatedReaders });
    };

    if (ticket === undefined || ticket == null || user == null) return <div>Karta nije dostupna za izmenu. Ne postoji ili nije aktivna.</div>;

    return (
        <div className='edit-form-container'>
            <div className='container-reader'>
                <h1>Uredi kartu</h1>
                
                <label className='edit-label-fix'>
                    Karta:
                    <input
                        type="text"
                        value={ticket.ticket_id}
                        readOnly
                    />
                </label>
                <label className='edit-label-fix'>
                    Datum i vreme izdavanja karte:
                    <input
                        type="text"
                        value={ticket.create_time}
                        readOnly
                    />
                </label>
                <form>
                    <label className='edit-label'>
                        Ime:
                        <input
                            type="text"
                            value={user.name}
                            onChange={(e) => setUser({ ...user, name: e.target.value })}
                        />
                    </label>
                    <label className='edit-label'>
                        Prezime:
                        <input
                            type="text"
                            value={user.surname}
                            onChange={(e) => setUser({ ...user, surname: e.target.value })}
                        />
                    </label>
                    <label className='edit-label'>
                        Broj telefona:
                        <input
                            type="text"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                    </label>
                    <label className='edit-label'>
                        Broj dana vazenja karte:
                        <input
                            type="text"
                            value={ticket.day_no}
                            onChange={(e) => setTicket({ ...ticket, day_no: e.target.value })}
                        />
                    </label>
                    <label className='edit-label'>
                        Broj prolaza:
                        <input
                            type="text"
                            value={ticket.entry_no}
                            onChange={(e) => setTicket({ ...ticket, entry_no: e.target.value })}
                        />
                    </label>
                    <label className='edit-label'>
                        Broj dnevnih prolaza:
                        <input
                            type="text"
                            value={ticket.daily_no}
                            onChange={(e) => setTicket({ ...ticket, daily_no: e.target.value })}
                        />
                    </label>
                    <label className='edit-label'>
                        Ulaz od:
                        <input
                            type="time"
                            id="hFrom"
                            value={ticket.h_from}
                            onChange={(e) => setTicket({ ...ticket, h_from: e.target.value })}
                        />
                    </label>
                    <label className='edit-label'>
                        Ulaz do:
                        <input
                            type="time"
                            id="hTo"
                            value={ticket.h_to}
                            onChange={(e) => setTicket({ ...ticket, h_to: e.target.value })}
                        />
                    </label>
                    {readers.map((reader, index) => (
                        <div key={index} className='checkbox-container'>
                            <input
                                type="checkbox"
                                checked={ticket.readers.includes(reader.id)}
                                onChange={(e) => handleCheckboxChange(reader.id, e.target.checked)}
                            />
                            <label>{reader.name}</label>
                        </div>
                    ))}
                    <div className='button-container'>
                        <button type="button" className='reader-button' onClick={handleSave}>Sačuvaj</button>
                        <button type="button" className='reader-button cancel-button' onClick={() => navigate('/')}>Otkaži</button>
                    </div>
                </form>
            </div>
            {errMsg && <Error message={errMsg} onClose={() => setErrMsg('')} />}
            {succ && <Success message={succ} onClose={() => setSucc('')} />}
        </div>
    );
};

export default EditTicket;
