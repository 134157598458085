import React from 'react'

const Home = () => {
  return (
    <div>
      <h1>HELLO! THIS IS HOME PAGE!</h1>
    </div>
  )
}

export default Home
