import React, {useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
import { getActiveTickets } from '../../api/axios';
import Table from '../Table/Table';

const ActiveTickets = () => {
  const [loading, setLoading] = useState(true);
  const [activeTickets, setActiveTickets] = useState([]);
  const location = useLocation();
  const { startDate, endDate } = location.state || {};

  useEffect(() => {
    const retreiveInfo = async () => {
        const response = await getActiveTickets(startDate, endDate);
        setActiveTickets(response);
        setLoading(false);
    };

    retreiveInfo();
  }, []);

  const column = [
    {heading: 'Karta', value: "ticket_id"},
    {heading: 'Datum kreiranja', value: "create_time"},
    {heading: 'Od (h)', value: "h_from"},
    {heading: 'Do (h)', value: "h_to"},
    {heading: 'Broj dana', value: "day_no"},
    {heading: 'Broj prolaza', value: "entry_no"},
    {heading: 'Broj dnevnih prolaza', value: "daily_no"},
  ];

  return (
    <div>
      <h2>Aktivne karte</h2>
      { loading ? (<p>Loading...</p>) : (
        <Table data={activeTickets} column={column}/>
        
      ) }
    </div>
  )
}

export default ActiveTickets
