import React, {useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
import Table from '../Table/Table';
import { getTypeTickets } from '../../api/axios';

const TypeTickets = () => {
  const [loading, setLoading] = useState(true);
  const [typeTickets, setTypeTickets] = useState([]);
  const location = useLocation();
  const { startDate, endDate, selectedOption } = location.state || {};

  useEffect(() => {
    const retreiveInfo = async () => {
        const response = await getTypeTickets(startDate, endDate, selectedOption);
        setTypeTickets(response);
        setLoading(false);
    };

    retreiveInfo();
  }, []);

  const column = [
    {heading: 'Kategorija', value: "category"},
    {heading: 'Tip', value: "type"},
    {heading: 'Vrsta', value: "kind"},
    {heading: 'Cena', value: "price"},
    {heading: 'Od (h)', value: "time_from"},
    {heading: 'Do (h)', value: "time_to"},
    {heading: 'Od datuma', value: "date_from"},
    {heading: 'Do datuma', value: "date_to"},
    {heading: 'Datum kreiranja', value: "date"},
    {heading: 'Vreme kreiranja', value: "time"},
    {heading: 'PDV', value: "vat"},
    {heading: 'Biletar', value: "log_card"},
    {heading: 'Nacin placanja', value: "payment_method"},
    {heading: 'QR', value: "qr_code"},
  ];

  return (
    <div>
      <h2>{selectedOption}</h2>
      { loading ? (<p>Loading...</p>) : (
        <Table data={typeTickets} column={column}/>
        
      ) }
    </div>
  )
}

export default TypeTickets
