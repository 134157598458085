import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { getSuccPassedTickets } from '../../api/axios'
import Table from '../Table/Table';

const SuccPassedTickets = () => {
  const [loading, setLoading] = useState(true);
  const [passedTickets, setPassedTickets] = useState([]);
  const location = useLocation();
  const { startDate, endDate } = location.state || {};

  useEffect(() => {
    const retreiveInfo = async () => {
      try {
        const response = await getSuccPassedTickets(startDate, endDate);
        
        setPassedTickets(response);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    retreiveInfo();
  }, []);

  const column = [
    { heading: 'Karta', value: "serial_num" },
    { heading: 'Datum i vreme', value: "datetime" },
    { heading: 'Barijera', value: "reader" },
    { heading: 'Rezultat', value: "message" },
    { heading: 'Ime', value: "name" },
    { heading: 'Prezime', value: "surname" },
    { heading: 'Ostalo', value: "phone" },
  ];

  return (
    <div>
      <h2>Uspesno prosle karte</h2>
      {loading ? (<p>Loading...</p>) : (
        <Table data={passedTickets} column={column} />

      )}
    </div>
  )
}

export default SuccPassedTickets
