import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DateRangePicker from '../Table/DateRangePicker';
import { getReportOptions } from '../../api/axios';
import './reportselection.css';
import DailyReport from '../Reports/DailyReport';

const ReportSelection = () => {
  const [reportType, setReportType] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [dateError, setDateError] = useState('');
  const navigate = useNavigate();
  const [reportCategoryOptions, setReportCategoryOptions] = useState([]);
  const [reportTypeOptions, setReportTypeOptions] = useState([]);
  const [reportKindOptions, setReportKindOptions] = useState([]);
  const [reportLogCardOptions, setReportLogCardOptions] = useState([]);
  const [reportPaymentOptions, setReportPaymentOptions] = useState([]);
  const [category, setCategory] = useState('category');
  const [type, setType] = useState('type');
  const [kind, setKind] = useState('kind');
  const [logCard, setLogCard] = useState('log_card');
  const [paymentMethod, setPaymentMethod] = useState('payment_method');
  const [ticket_id, setTicketID] = useState('');
  // const [, ] = useState('');
  // const [, ] = useState('');
  // const [, ] = useState('');
  // const [, ] = useState('');

  useEffect(() => {
    const fetchReportOptions = async () => {
      try {

        const responses = await getReportOptions(category);
        const categoryOptions = responses;
        //console.log("CATEGORY OPTIONS: " + categoryOptions);
        setReportCategoryOptions(categoryOptions);

        const responses1 = await getReportOptions(type);
        const typeOptions = responses1;
        //console.log("TYPE OPTIONS: " + typeOptions);
        setReportTypeOptions(typeOptions);

        const responses2 = await getReportOptions(kind);
        const kindOptions = responses2;
        //console.log("KIND OPTIONS: " + kindOptions);
        setReportKindOptions(kindOptions);

        const responses3 = await getReportOptions(logCard);
        const logOptions = responses3;
        //console.log("LOG OPTIONS: " + logOptions);
        setReportLogCardOptions(logOptions);

        const responses4 = await getReportOptions(paymentMethod);
        const paymentOptions = responses4;
        //console.log("PAYMENT OPTIONS: " + paymentOptions);
        setReportPaymentOptions(paymentOptions);

      } catch (error) {
        console.error('Error fetching report options:', error);
      }
    };

    fetchReportOptions();
  }, []);

  const handleDateRangeChange = (start, end, error) => {
    setStartDate(start);
    setEndDate(end);
    setDateError(error);
  };

  const handleReportChange = (event) => {
    const [route, option] = event.target.value.split('-');
    setReportType(route);
    setSelectedOption(option);
    //console.log(reportType, selectedOption);
  };

  useEffect(() => {
    if (reportType && startDate && endDate && !dateError) {
      setIsButtonEnabled(true);
    } else {
      setIsButtonEnabled(false);
    }
  }, [reportType, startDate, endDate, dateError]);

  const handleSubmit = () => {
    if (reportType && startDate && endDate) {
      navigate(`/${reportType}`, { state: { startDate, endDate, selectedOption } });
    } else {
      alert('Please select a report and date range.');
    }
  };

  const handleIDSearch = () => {
    if (ticket_id) {
      const newTabUrl = `/ticket-info?ticket_id=${ticket_id}`;
      window.open(newTabUrl, '_blank');
    }
  };
  const handleEditTicket = () => {
    if (ticket_id) {
      navigate(`/ticket/edit/${ticket_id}`);
    }
  };

  return (
    <div className='container'>
      <div className="part1">
        <h2 className='title'>Pretraga po broju kartice</h2>
        <input
          type="text"
          id="ticketId"
          className=""
          placeholder='Broj kartice'
          value={ticket_id}
          onChange={(e) => setTicketID(e.target.value)}
        />
        <button
          className=''
          onClick={handleIDSearch}
        >
          Pretrazi
        </button>
        <h2 className='title'>Izmeni podatke o kartici</h2>
        <input
          type="text"
          id="ticketId"
          className=""
          placeholder='Broj kartice'
          value={ticket_id}
          onChange={(e) => setTicketID(e.target.value)}
        />
        <button
          className=''
          onClick={handleEditTicket}
        >
          Pretrazi
        </button>
      </div>
      <div className="part2">
        <h2 className='title'>Izaberi izvestaj i datum</h2>
        <select onChange={handleReportChange}>
          <option key="" value="">Izaberi izvestaj</option>
          <option key="dailyrep" value="dailyrep">Dnevni izvestaj</option>
          <option key="active" value="active">Aktivne Karte</option>
          <option key="passed" value="passed">Prosle Karte</option>
          <option key="succpassed" value="succ_passed">Uspesno Prosle Karte</option>
          <option key="nonactive" value="nonactive">Neaktivne Karte</option>
          <option key="sold" value="sold">Prodate Karte</option>
          <optgroup label='Category'>
            {Array.isArray(reportCategoryOptions) && reportCategoryOptions.map((option, index) => (
              <option key={option + `_${index}`} value={category + `-${option}`}>
                {option.charAt(0).toUpperCase() + option.slice(1)}
              </option>
            ))}
          </optgroup>
          <optgroup label='Type'>
            {Array.isArray(reportTypeOptions) && reportTypeOptions.map((option, index) => (
              <option key={option + `_${index}`} value={type + `-${option}`}>
                {option.charAt(0).toUpperCase() + option.slice(1)}
              </option>
            ))}</optgroup>
          <optgroup label='Kind'>
            {Array.isArray(reportKindOptions) && reportKindOptions.map((option, index) => (
              <option key={option + `_${index}`} value={kind + `-${option}`}>
                {option.charAt(0).toUpperCase() + option.slice(1)}
              </option>
            ))}</optgroup>
          <optgroup label='Log Card'>
            {Array.isArray(reportLogCardOptions) && reportLogCardOptions.map((option, index) => (
              <option key={option + `_${index}`} value={logCard + `-${option}`}>
                {option.charAt(0).toUpperCase() + option.slice(1)}
              </option>
            ))}</optgroup>
          <optgroup label='Payment Method'>
            {Array.isArray(reportPaymentOptions) && reportPaymentOptions.map((option, index) => (
              <option key={option + `_${index}`} value={paymentMethod + `-${option}`}>
                {option.charAt(0).toUpperCase() + option.slice(1)}
              </option>
            ))}</optgroup>
        </select>
        <DateRangePicker onDateRangeChange={handleDateRangeChange} />
        <button
          className={`report-button ${isButtonEnabled ? '' : 'disabled-button'}`}
          onClick={handleSubmit}
          disabled={!isButtonEnabled}>
          Generisi izvestaj
        </button>
      </div>
      <div className="part3">
      <h2 className='title' title='Popuniti bilo koje polje'>Pretraga po korisniku</h2>
        <input
          type="text"
          id="name"
          className=""
          placeholder='Ime'
        // value={}
        // onChange={(e) => (e.target.value)}
        />
        <input
          type="text"
          id="surname"
          className=""
          placeholder='Prezime'
        // value={}
        // onChange={(e) => (e.target.value)}
        />
        <input
          type="text"
          id="phone"
          className=""
          placeholder='Broj telefona'
        // value={}
        // onChange={(e) => (e.target.value)}
        />
        <button
          className=''
        //onClick={}
        >
          Pretrazi
        </button>
      </div>
      <div className="part4"></div>
      <div className="part5"></div>
      <div className="part6"></div>

    </div>
  );
};

export default ReportSelection;
