import React, {useEffect, useState} from 'react'
import { useLocation } from 'react-router-dom';
import {getNonActiveTickets} from '../../api/axios'
import Table from '../Table/Table';

const NonactiveTickets = () => {
  const [loading, setLoading] = useState(true);
  const [nonactiveTickets, setNonactiveTickets] = useState([]);
  const location = useLocation();
  const { startDate, endDate } = location.state || {};
  useEffect(() => {
    const retreiveInfo = async () => {
        const response = await getNonActiveTickets(startDate, endDate);
        setNonactiveTickets(response);
        setLoading(false);
    };

    retreiveInfo();
  }, []);

  const column = [
    {heading: 'Karta', value: "ticket_id"},
    {heading: 'Datum kreiranja', value: "create_time"},
    {heading: 'Od (h)', value: "h_from"},
    {heading: 'Do (h)', value: "h_to"},
    {heading: 'Broj dana', value: "day_no"},
    {heading: 'Broj prolaza', value: "entry_no"},
    {heading: 'Broj dnevnih prolaza', value: "daily_no"},
  ];

  return (
    <div>
      <h2>Neaktivne karte</h2>
      { loading ? (<p>Loading...</p>) : (
        <Table data={nonactiveTickets} column={column}/>
        
      ) }
    </div>
  )
}

export default NonactiveTickets
