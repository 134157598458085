import React, {useEffect, useState} from 'react'
import { useLocation } from 'react-router-dom';
import {getSoldTickets} from '../../api/axios'
import Table from '../Table/Table';

const SoldTickets = () => {
  const [loading, setLoading] = useState(true);
  const [soldTickets, setSoldTickets] = useState([]);
  const location = useLocation();
  const { startDate, endDate } = location.state || {};

  useEffect(() => {
    const retreiveInfo = async () => {
        const response = await getSoldTickets(startDate, endDate);
        setSoldTickets(response);
        setLoading(false);
    };

    retreiveInfo();
  }, []);

  const column = [
    {heading: 'Kategorija', value: "category"},
    {heading: 'Vrsta', value: "kind"},
    {heading: 'Tip', value: "type"},
    {heading: 'Cena', value: "price"},
    {heading: 'Biletar', value: "log_card"},
  ];

  return (
    <div>
      <h2>Prodate karte</h2>
      { loading ? (<p>Loading...</p>) : (
        <Table data={soldTickets} column={column}/>
        
      ) }
    </div>
  )
}

export default SoldTickets
