import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getDailyReport } from '../../api/axios';
import './dailyReport.css';
import DailyCSV from '../CSV/DailyCSV';

const DailyReport = () => {
    const [report, setReport] = useState(null);
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const { startDate, endDate } = location.state || {};

    useEffect(() => {
        const retreiveInfo = async () => {
            const response = await getDailyReport(startDate, endDate);
            setReport(response);
            setLoading(false);
        };

        retreiveInfo();
    }, [startDate, endDate]);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="daily-report">
            <h1 className="daily-report-title">Dnevni finansijski izvestaj</h1>
            <div className="daily-report-summary">
                <h2 className="daily-report-subtitle">SUMA</h2>
                <p>Ukupno prodatih karata:</p><p className='daily-report-result'> {report.total.tickets_sold}</p>
                <p>Ukupna zarada:</p><p className='daily-report-result'> {parseFloat(report.total.total_price || 0).toFixed(2)}</p>
            </div> {console.log(report)}
            <DailyCSV data={report} fileName={"download"} />
            <div>
                <h2 className="daily-report-subtitle">KATEGORIJA</h2>
                <Table data={report.by_category} groupBy="category" />
            </div>
            <div>
                <h2 className="daily-report-subtitle">TIP</h2>
                <Table data={report.by_type} groupBy="type" />
            </div>
            <div>
                <h2 className="daily-report-subtitle">VRSTA</h2>
                <Table data={report.by_kind} groupBy="kind" />
            </div>
            <div>
                <h2 className="daily-report-subtitle">NACIN PLACANJA</h2>
                <Table data={report.by_payment_method} groupBy="payment_method" />
            </div>
            <div>
                <h2 className="daily-report-subtitle">BILETAR</h2>
                <Table data={report.by_log_card} groupBy="log_card" />
            </div>
        </div>
    );
}

function Table({ data, groupBy }) {
    const getHeaderTitle = (groupBy) => {
        switch (groupBy) {
          case 'category':
            return 'Kategorija';
          case 'type':
            return 'Tip';
          case 'kind':
            return 'Vrsta';
          case 'payment_method':
            return 'Nacin placanja';
          case 'log_card':
            return 'Biletar';
          default:
            return 'Grupa';
        }
      };

    return (
        <table className="daily-report-table">
            <thead>
                <tr>
                    <th>{getHeaderTitle(groupBy)}</th>
                    <th>Broj prodatih karata</th>
                    <th>Ukupna cena</th>
                </tr>
            </thead>
            <tbody>
                {data.map((row, index) => (
                    <tr key={index}>
                        <td>{row[groupBy]}</td>
                        <td>{row.tickets_sold}</td>
                        <td>{parseFloat(row.total_price || 0).toFixed(2)}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

export default DailyReport;
