import React, { useEffect, useState } from 'react';
import './newAppUser.css';
import { getUserExist, postRequest } from '../../api/axios';
import Error from '../Error/Error';
import Success from '../Success/Success';

const NewAppUser = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [idError, setIdError] = useState('');
    const [succ, setSucc] = useState('');

    const checkUserExists = async () => {
        if (username) {
            try {
                const response = await getUserExist(username);
                if (response.success) {
                    setIdError('User exists.');
                    return false;
                } else {
                    setIdError('');
                    return true;
                }
            } catch (error) {
                console.error('Error checking user:', error);
                setIdError('Greška pri proveri korisnika.');
                return false;
            }
        }
        return false;
    };

    useEffect(() => {
        if(password !== confirmPassword){
            setErrMsg("Loznike se ne poklapaju.");
        } else {
            setErrMsg('');
        }
    }, [confirmPassword]);

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (username == '' || password == '') {
            setErrMsg('All fields are required.');
            return;
        }
        const userExists = await checkUserExists();
        if (!userExists) {
            return;
        }
        // slanje backendu
        const formData = new URLSearchParams();
        formData.append('username', username);
        formData.append('password', password);
        console.log(username, " ", password);
        const response = await postRequest("/users/add", formData);
        if (!response.success) {
            console.log(response.message);
           // setErrMsg("Not added.");
        } else {
            setIdError('');
            setUsername('');
            setPassword('');
            setSucc('Successfully added!');
        }
    };

    return (
        <div className="container-user">
            <h1>Nov korisnik</h1>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="username">Korisnicko ime:</label>
                    <input
                        type="text"
                        id="username"
                        className="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Lozinka:</label>
                    <input
                        type="password"
                        id="password"
                        className="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Potvrdi lozinku:</label>
                    <input
                        type="password"
                        id="confirmPassword"
                        className="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                </div>
                <button type="submit" className="user-button">Dodaj</button>
            {errMsg && <Error message={errMsg} onClose={() => setErrMsg('')} />}
            {succ && <Success message={succ} onClose={() => setSucc('')} />}
            {idError && <Error message={idError} onClose={() => setIdError('')} />}
            </form>
        </div>
    );
};

export default NewAppUser;
