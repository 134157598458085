import React from 'react';
import './navbar.css';
import Logout from '../Logout/Logout';
import DownloadApp from '../DownloadAPP/DownloadAPP';
import { hasPermission } from '../../context/AuthFunc';
import { PERMISSIONS } from '../../constants/const';

const Navbar = ({isLoggedIn, setIsLoggedIn}) => {
  return (
    <nav>
      <ul>
        {/* <li><a href="/home">Home</a></li> */}
        <li><a href="/">Pocetna</a></li>
        {hasPermission(PERMISSIONS.CREATE_NEW_READER) && <li className="dropdown">
            <a href="/readers">Citaci</a>
            <div className="dropdown-content">
              <a href="/new-reader">Dodaj citac</a>
              <a href="/readers">Izmeni/Obrisi</a>
            </div>
          </li>}
        {hasPermission(PERMISSIONS.CREATE_NEW_TICKET) && <li><a href="/new-ticket">Dodaj kartu</a></li>}
        {hasPermission(PERMISSIONS.CREATE_NEW_USER) && <li><a href="/new-user">Dodaj korisnika</a></li>}
        {hasPermission(PERMISSIONS.VIEW_MONITORING) && <li><a href="/monitoring">Monitor</a></li>}
        {hasPermission(PERMISSIONS.VIEW_ACCESS_RIGHTS) && <li><a href="/access">Prava</a></li>}
        {/* <li><a href="/settings">Settings</a></li> */}
        <li className='download-button-container'>
          <DownloadApp />
        </li>
        <li className='logout-button-container'><Logout isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn}/></li>
      </ul>
    </nav>
  );
};

export default Navbar;
